import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import FeeCard from "./FeeCard";
import LoungeCard from "./LoungeCard";
import RewardCard from "./RewardCard";

const DetailsPage = () => {
  const { nameCreditCard } = useParams();
  const [selectedCard, setSelectedCard] = useState(null);
  //   console.log("name in dp: ", nameCreditCard);
  const name = nameCreditCard.replace("CreditCard", "");
  //   console.log("name in dp 2: ", name);

  const toTitleCase = (str) => {
    return str.toLowerCase().replace(/(^|\s)\S/g, (char) => char.toUpperCase());
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          process.env.PUBLIC_URL + "/data/cc_details.json"
        );
        if (response.ok) {
          const data = await response.json();

          const card = data.find((card) => card.name.replace(" ", "") == name);
          if (card) {
            setSelectedCard(card);
          } else {
            console.error("Card details not found");
          }
        } else {
          console.error("Failed to fetch JSON data.");
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    };

    fetchData();
  }, [name]);

  if (!selectedCard) {
    return <CircularProgress />;
  }

  // Render metrics conditionally based on their existence in selectedCard object
  const renderMetric = (key, label) => {
    if (key === "top_benefits" && selectedCard.hasOwnProperty(key)) {
      const benefitsList = selectedCard[key]
        .split(";")
        .map((benefit, index) => <li key={index}>{benefit.trim()}</li>);
      return (
        <div style={{ marginLeft: "5%" }} key={key}>
          <strong>{label}:</strong>
          <ul>{benefitsList}</ul>
        </div>
      );
    } else if (
      !key.includes("fee") &&
      !key.includes("lounge") &&
      !key.includes("reward") &&
      selectedCard.hasOwnProperty(key)
    ) {
      return (
        <div style={{ marginLeft: "5%", paddingBottom: "2%" }} key={key}>
          <strong>{label}:</strong> {selectedCard[key]}
        </div>
      );
    }
    return null;
  };

  return (
    <div style={{ maxWidth: "auto", margin: "2 auto" }}>
      <React.Fragment>
        <CssBaseline />
        <Container maxWidth="sm" sx={{ borderRadius: "40px" }}>
          <Box
            sx={{ bgcolor: "#cfe8fc", height: "auto", borderRadius: "40px" }}>
            <div
              style={{
                fontFamily: "Roboto, sans-serif",
                padding: "10px",
                marginBottom: "20px",
              }}>
              <h2 style={{ textAlign: "-webkit-center" }}>
                {selectedCard.bank.toUpperCase() + " "}
                {selectedCard.name} Credit Card
              </h2>

              {renderMetric("welcome_benefit", "Welcome Benefit")}
              {renderMetric("milestone_benefit", "Milestone Benefit")}
              {renderMetric("brand_benefit", "Benefit Brands")}
              {renderMetric("top_benefits", "Top Benefits")}

              <RewardCard selectedCard={selectedCard} />
              <LoungeCard selectedCard={selectedCard} />
              <FeeCard selectedCard={selectedCard} />
            </div>
          </Box>
        </Container>
      </React.Fragment>
    </div>
  );
};

export default DetailsPage;

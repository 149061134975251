import React, { useState } from "react";
import { Typography, Layout, Menu, theme } from "antd";
import {
  RightOutlined,
  MenuOutlined,
  TwitterOutlined,
  LinkedinOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import firebase from "firebase/compat/app";
import { auth } from "../firebase";
import AbdulImage from "./photos/Abdul.jpg";
import "./css/About.css";

const { Header, Content, Footer } = Layout;

const handleGoogleSignIn = async () => {
  const provider = new firebase.auth.GoogleAuthProvider();
  try {
    await auth.signInWithPopup(provider);
    console.log("User logged in with Google");
  } catch (error) {
    console.error("Error signing in with Google:", error);
  }
};

const About = () => {
  const [collapsed, setCollapsed] = useState(true);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const menuItems = [
    { key: "1", label: "Home", path: "/" },
    { key: "2", label: "About", path: "/about" },
    // { key: "3", label: "Contact", path: "/contact" },
    { key: "4", label: "Sign in with Google", onClick: handleGoogleSignIn },
  ];

  const handleTwitterClick = () => {
    window.open("https://twitter.com/khanthecoder", "_blank");
  };

  const handleLinkedInClick = () => {
    window.open("https://www.linkedin.com/in/abdulrawoofkhan/", "_blank");
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header
        className="header"
        style={{
          background: theme.useToken().token.colorBgContainer,
          padding: "0 0px",
          width: "100%",
        }}>
        <Menu
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={["2"]}
          style={{ lineHeight: "64px", width: "100%" }}
          collapsed={collapsed}>
          {menuItems.map((item) => (
            <Menu.Item
              key={item.key}
              style={{ fontSize: "14px" }}
              onClick={item.onClick}>
              {item.path ? (
                <Link key={item.key} to={item.path}>
                  {item.label}
                </Link>
              ) : (
                <span key={item.key}>{item.label}</span>
              )}
            </Menu.Item>
          ))}
        </Menu>
      </Header>
      <Content className="content">
        <div className="about-content">
          <div className="about-image">
            <img src={AbdulImage} alt="Your Photo" className="profile-image" />
          </div>
          <div className="about-details">
            <Typography.Title level={1} className="name">
              Hi, I'm Abdul!
            </Typography.Title>
            <Typography.Text className="age">
              {" "}
              I'm 23 years old.{" \n"}
            </Typography.Text>
            <Typography.Text className="description">
              I build software products that solve probems.
            </Typography.Text>
            <Typography.Text className="connect">
              Let's connect!
            </Typography.Text>
          </div>
          <div className="social-icons">
            <TwitterOutlined className="icon" onClick={handleTwitterClick} />
            <LinkedinOutlined className="icon" onClick={handleLinkedInClick} />
          </div>
        </div>
      </Content>
      <Footer style={{ textAlign: "center" }}>
        Made with ❤️ in Bengaluru, India
      </Footer>
    </Layout>
  );
};

export default About;

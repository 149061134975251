import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

const FeeCard = ({ selectedCard }) => {
  return (
    <Card sx={{ maxWidth: "70%", margin: "0 auto", marginBottom: "20px" }}>
      <CardContent style={{ textAlign: "center" }}>
        <Typography variant="h5" component="div" fontWeight="bold">
          Lounge visits
        </Typography>
        <div
          style={{ display: "flex", alignItems: "center", margin: "10px 0" }}>
          <div style={{ paddingLeft: "10px", flex: 1 }}>
            <Typography variant="body2" color="textSecondary">
              Domestic
            </Typography>
            <Typography variant="body1" color="textPrimary">
              {selectedCard.domestic_lounge_visits}
            </Typography>
          </div>
          <div
            style={{
              borderLeft: "1px solid #ccc",
              paddingLeft: "10px",
              flex: 1,
            }}>
            <Typography variant="body2" color="textSecondary">
              International
            </Typography>
            <Typography variant="body1" color="textPrimary">
              {selectedCard.int_lounge_visits}
            </Typography>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default FeeCard;

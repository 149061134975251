import React, { useState, useEffect } from "react";
import { Table } from "antd";
import "./css/Table.css";
const { Column } = Table;

const TableComponent = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    // Read the data from the JSON file
    fetch(process.env.PUBLIC_URL + "/data/cc_details.json")
      .then((response) => response.json())
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  return (
    <Table dataSource={data} scroll={{ x: "100%" }} className="dark-mode">
      <Column
        title="#"
        dataIndex="record_id"
        key="record_id"
        sorter={(a, b) => a.record_id - b.record_id}
        sortDirections={["ascend", "descend"]}
        width="1%"
      />
      <Column
        title="Bank"
        dataIndex="bank"
        key="bank"
        sorter={(a, b) => a.bank.localeCompare(b.bank)}
        sortDirections={["ascend", "descend"]}
        width="1%"
      />
      <Column
        title="Name"
        dataIndex="name"
        key="name"
        sorter={(a, b) => a.name.localeCompare(b.name)}
        sortDirections={["ascend", "descend"]}
        width="1%"
      />
      <Column
        title="Joining Fee"
        dataIndex="joining_fee"
        key="joining_fee"
        sorter={(a, b) => a.joining_fee - b.joining_fee}
        sortDirections={["ascend", "descend"]}
        width="1%"
      />
      <Column
        title="Annual Fee"
        dataIndex="annual_fee"
        key="annual_fee"
        sorter={(a, b) => a.annual_fee - b.annual_fee}
        sortDirections={["ascend", "descend"]}
        width="1%"
      />
      <Column
        title="Top Benefits"
        dataIndex="top_benefits"
        key="top_benefits"
        width="10%"
      />
      <Column
        title="Milestone Benefit"
        dataIndex="milestone_benefit"
        key="milestone_benefit"
        width="10%"
      />
      <Column
        title="Reward Type"
        dataIndex="reward_type"
        key="reward_type"
        sorter={(a, b) => a.reward_type.localeCompare(b.reward_type)}
        sortDirections={["ascend", "descend"]}
        width="1%"
      />
      <Column
        title="Reward Rate"
        dataIndex="reward_rate"
        key="reward_rate"
        sorter={(a, b) => a.reward_rate - b.reward_rate}
        sortDirections={["ascend", "descend"]}
        width="1%"
      />
      <Column
        title="Domestic Lounge Visits"
        dataIndex="domestic_lounge_visits"
        key="domestic_lounge_visits"
        sorter={(a, b) => a.domestic_lounge_visits - b.domestic_lounge_visits}
        sortDirections={["ascend", "descend"]}
        width="1%"
      />
      <Column
        title="International Lounge Visits"
        dataIndex="int_lounge_visits"
        key="int_lounge_visits"
        sorter={(a, b) => a.int_lounge_visits - b.int_lounge_visits}
        sortDirections={["ascend", "descend"]}
        width="1%"
      />
    </Table>
  );
};

export default TableComponent;

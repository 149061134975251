import { Typography, Layout, Menu, theme, Space } from "antd";
import React, { useState } from "react";
import firebase from "firebase/compat/app";
import TableComponent from "./Table";
import {
  RightOutlined,
  MenuOutlined,
  PoweroffOutlined,
} from "@ant-design/icons";
import { auth } from "../firebase";
import "./css/Login.css";
import { Link } from "react-router-dom";

const { Header, Content, Footer, Sider } = Layout;

const handleGoogleSignIn = async () => {
  const provider = new firebase.auth.GoogleAuthProvider();
  try {
    await auth.signInWithPopup(provider);
    console.log("User logged in with Google");
  } catch (error) {
    console.error("Error signing in with Google:", error);
  }
};

const handleLogout = () => {
  firebase.auth().signOut();
  console.log("logged out");
};

const logoutOption = {
  key: "5",
  label: (
    <span>
      <PoweroffOutlined /> Logout
    </span>
  ),
  onClick: handleLogout,
};

const Navbar = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const menuItems = [
    { key: "1", label: "Home", path: "/" },
    // { key: "2", label: "About", path: "/about" },
    // { key: "3", label: "Contact", path: "/contact" },
    { key: "4", label: "Sign in with Google", onClick: handleGoogleSignIn },
    // { key: "5", label: "Logout", onClick: handleLogout },
  ];

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Layout className="site-layout">
        <Header
          className="header"
          style={{
            background: colorBgContainer,
            display: "flex",
            alignItems: "center",
            padding: "0px",
            height: "14px",
          }}>
          <Menu
            theme="dark"
            mode="horizontal"
            defaultSelectedKeys={["1"]}
            style={{ flexGrow: 1 }}
            items={menuItems} // Use the `items` prop instead of `children`
          />
        </Header>

        <Content
          className="content"
          style={{
            marginTop: "10px",
            padding: "0 16px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          }}>
          <div className="site-layout-content">
            <div style={{ padding: "8% 0%" }}>
              <img
                style={{
                  width: "30%",
                  borderRadius: "8px",
                  overflow: "hidden",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                }}
                src={process.env.PUBLIC_URL + "/images/bcci_logo.png"}
                id="company_logo"
              />

              <Typography.Title level={3} style={{ margin: 0 }}>
                Best credit card India
              </Typography.Title>
              {/* <Typography.Title level={5} style={{ margin: 0 }}>
                KCET 2nd extended round 2022
              </Typography.Title> */}
              {/* <Typography.Title level={5} style={{ margin: 0 }}>
                General Region
              </Typography.Title> */}
            </div>
            <TableComponent />
          </div>
        </Content>

        <Footer style={{ textAlign: "center" }}>
          Made with ❤️ in Bengaluru, India
        </Footer>
      </Layout>
    </Layout>
  );
};

export default Navbar;

import React, { useState } from "react";
import ReactDOM from "react-dom";

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import Login from "./Login";
import Homepage from "./Homepage";
import About from "./components/About";
import DetailsPage from "./components/DetailsPage"; // Import your DetailsPage component here

import { useAuthState } from "react-firebase-hooks/auth";
import { useCollectionData } from "react-firebase-hooks/firestore";

firebase.initializeApp({
  apiKey: "AIzaSyAmmkKkgS30R57UkMEFlma_jL8tfC3EgmA",
  authDomain: "best-credit-card-india.firebaseapp.com",
  projectId: "best-credit-card-india",
  storageBucket: "best-credit-card-india.appspot.com",
  messagingSenderId: "1050598877157",
  appId: "1:1050598877157:web:32c5d051ea556b946d710c",
  measurementId: "G-85ZTLXS7EY",
});

const auth = firebase.auth();

// Firebase initialization and configuration

function App() {
  const [user, setUser] = useState(null);

  // Check if the user is logged in
  firebase.auth().onAuthStateChanged((loggedInUser) => {
    if (loggedInUser) {
      setUser(loggedInUser);
    } else {
      setUser(null);
    }
  });
  return <div className="App">{user ? <Homepage /> : <Homepage />}</div>;
}

export default App;

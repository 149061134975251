import React, { useEffect, useState } from "react";
import Block from "./Block";
import Card from "./Card";
import "./css/Dashboard.css";

const Dashboard = () => {
  const [ccDetails, setCcDetails] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          process.env.PUBLIC_URL + "/data/cc_details.json"
        );
        if (response.ok) {
          const data = await response.json();
          setCcDetails(data);
        } else {
          console.error("Failed to fetch JSON data.");
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="dashboard">
      <h1>Credit Card Comparison</h1>
      <div className="card-container">
        {ccDetails.map((card, index) => (
          <Card
            key={card.record_id}
            name={card.name}
            title={card.name}
            // onClick={() => handleCardClick(index)}
            bank={card.bank}
            reward_type={card.reward_type}
            reward_rate={card.reward_rate}
            joining_fee={card.joining_fee}
            annual_fee={card.annual_fee}
            domestic_lounge_visits={card.domestic_lounge_visits}
            int_lounge_visits={card.int_lounge_visits}></Card>
        ))}
      </div>
    </div>
  );
};

export default Dashboard;

import React from "react";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";

import "./css/Card.css";
import "./css/Fonts.css";

import flag from "./photos/indian_flag.png";
import globe from "./photos/globe.png";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

const Card = ({
  name,
  title,
  onClick,
  bank,
  reward_type,
  reward_rate,
  joining_fee,
  annual_fee,
  domestic_lounge_visits,
  int_lounge_visits,
  children,
  backgroundColor,
}) => {
  const backgroundColors = ["#CDFAD5", "#F6FDC3", "#FDE5D4", "#E4F1FF"];

  const toTitleCase = (str) => {
    return str.toLowerCase().replace(/(^|\s)\S/g, (char) => char.toUpperCase());
  };
  const getRandomColor = () => {
    const randomIndex = Math.floor(Math.random() * backgroundColors.length);
    return backgroundColors[randomIndex];
  };

  const getTextColor = (bgColor) => {
    const colorMap = {
      "#CDFAD5": "#6FF187",
      "#F6FDC3": "#E8FA61",
      "#FDE5D4": "#F8AA72",
      "#E4F1FF": "#80BDFF",
    };

    return colorMap[bgColor] || "#000000";
  };

  const cardStyle = {
    border: "1px solid #ccc",
    borderRadius: "10px",
    padding: "10px",
    backgroundColor: getRandomColor(),
    cursor: "pointer",
    color: getTextColor(backgroundColor),
    margin: "0 10px",
    width: "calc(100% - 20px)",
    fontFamily: "Roboto, sans-serif",
  };

  const nameCreditCard = `${name.replace(" ", "")}CreditCard`;
  return (
    <div className="card" style={cardStyle} onClick={onclick}>
      <Link
        to={`/${nameCreditCard}`}
        style={{ textDecoration: "none", color: "black" }}>
        <div style={{ marginTop: "0px", fontSize: "10px" }}>
          <b>{bank.toUpperCase()}</b>
        </div>
        <div style={{ float: "right", fontSize: "15px" }}>
          <div>
            <Typography variant="body1" color="textPrimary">
              {reward_rate}% {reward_type}
            </Typography>
          </div>
          <div>
            <Typography variant="body1" color="textPrimary">
              Joining fee: {joining_fee}{" "}
            </Typography>
          </div>
          <div>
            <Typography variant="body1" color="textPrimary">
              Annual fee:{annual_fee}
            </Typography>
          </div>
          <div>
            <Typography variant="body1" color="textPrimary">
              Lounge
              <img
                src={flag}
                alt=""
                style={{
                  width: "13px",
                  height: "13px",
                  marginLeft: "5px",
                  marginBottom: "3px",
                  verticalAlign: "middle",
                }}
              />
              {domestic_lounge_visits}
              <img
                src={globe}
                alt=""
                style={{
                  width: "13px",
                  height: "13px",
                  marginLeft: "5px",
                  marginBottom: "3px",
                  verticalAlign: "middle",
                }}
              />
              {int_lounge_visits}
            </Typography>
          </div>
        </div>
        <h3>{toTitleCase(title.replace(/-/g, " "))}</h3>
        {children}
      </Link>
    </div>
  );
};

export default Card;

import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

const FeeCard = ({ selectedCard }) => {
  return (
    <Card
      sx={{
        maxWidth: "70%",
        margin: "0 auto",
        marginBottom: "5%",
        marginTop: "5%",
      }}>
      <CardContent style={{ textAlign: "center" }}>
        <Typography variant="h5" component="div" fontWeight="bold">
          Reward
        </Typography>
        <div
          style={{ display: "flex", alignItems: "center", margin: "10px 0" }}>
          <div style={{ paddingLeft: "10px", flex: 1 }}>
            <Typography variant="h6" color="textPrimary">
              {selectedCard.reward_rate}% {selectedCard.reward_type}
            </Typography>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default FeeCard;

import React from "react";
import Navbar from "./components/Navbar";
import About from "./components/About";
import DetailsPage from "./components/DetailsPage";
import { Routes, Route } from "react-router-dom";

import "./components/css/Homepage.css";

import ReactDOM from "react-dom";
import { Layout, Space, Button } from "antd";
import Dashboard from "./components/Dashboard";

const { Header, Footer, Sider, Content } = Layout;
function Homepage() {
  return (
    <div style={{ padding: 0 }} className="homepage">
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/about" element={<About />} />
        <Route path="/:nameCreditCard" element={<DetailsPage />} />
      </Routes>
    </div>
  );
}

export default Homepage;
